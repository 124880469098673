import { Component, mixins } from 'nuxt-property-decorator';
import { MetaInfo } from 'vue-meta';

import { sectionId } from './index/-components/MyHotelSection';
import {
  HHotelGalleriesModal,
  HHotelGalleriesModalRef,
  HHotelToBooking,
  HPortalItem,
} from '~/components';
import NavigationHooksMixin, {
  NavigationHooksStack,
} from '~/mixins/navigation-hooks';
import {
  HotelDetail,
  LanguageRedirectResult,
  NormalizedHotelFlowLineSettings,
  normalizeHotelFlowLineSettings,
} from '~/schemes';
import type HDefaultLayout from '~/layouts/default';
import { generateHreflang } from '~/helpers';

@Component<HotelViewRoot>({
  scrollToTop: true,
  inject: ['layout'],
  provide() {
    return {
      hotelViewRoot: this,
    };
  },

  render() {
    return (
      <div staticClass="my-hotel-root-view" id={this.domId}>
        <nuxt-child />
        <HHotelGalleriesModal
          ref="galleriesModal"
          showcase
          galleries={this.hotel.galleries}
          galleryCategories={this.$commons.galleryCategories}
          v-model={this.galleryModalActive}
        />
        {!this.useLocalFooter && !this.hiddenFooter && (
          <HPortalItem to="portal-footer">
            <HHotelToBooking
              staticClass="my-hotel-root-view__portal-footer"
              hotel={this.hotel}
              eventId="fixedarea"
            />
          </HPortalItem>
        )}
      </div>
    );
  },

  /**  Nuxtのライフサイクル */
  async asyncData(ctx) {
    try {
      const hotelSource = await ctx.$api.hotels.getBySlug(
        ctx.params.hotel_slug,
      );

      if (!hotelSource) {
        ctx.error({ statusCode: 404 });
        return;
      }

      /**
       * @todo
       * 有効言語リダイレクト
       * これは将来的にホテルページより上位の階層でも行うはずなので、
       * その時はここではなく、serverMiddlewareでやる
       */
      const currentLang = ctx.$language.current;
      const { availableLanguages } = hotelSource;
      const matched = availableLanguages.find((l) => l.id === currentLang);
      if (matched && matched.external && !ctx.$dataBucket.isPreview) {
        if (process.browser) {
          location.replace(matched.external);
        } else {
          /**
           * ここでヘッダ送信（end()）しちゃうとserver側のmiddlewareチェーンが例外吐くけど、
           * これしか現状良い場所がないので気にしない事にする
           * NuxtのFeatureに期待か別の仕組み検討
           */
          ctx.res.writeHead(302, { Location: matched.external });
          ctx.res.end();
        }
        return;
      }
      const redirectResult = ctx.$language.checkAndRedirect(
        availableLanguages.map((i) => i.id),
      );
      if (redirectResult === LanguageRedirectResult.Redirectd) {
        return;
      } else if (redirectResult === LanguageRedirectResult.Missing) {
        ctx.error({ statusCode: 404 });
        return;
      }

      if (!hotelSource.brand) {
        ctx.error({ statusCode: 404 });
        return;
      }

      await ctx.$hotel.setCurrent(hotelSource);

      // ブラウザ側でasyncDataフックが呼ばれた時は、この時点で宿GETS施設マスタをロードしておく
      // このコンポーネントのmountedフックでも同様にマスタのロードを行っているが、すでにロード済みの場合はキャンセルされるので問題ない
      if (process.browser) {
        await ctx.$hotel.loadCurrentHotelYgetsDetail();
      }

      return {
        hotelSource,
      };
    } catch (_err) {
      ctx.$error.throw(_err);
    }
  },

  head() {
    const head: MetaInfo = {};
    const { hotel, brand, hotelSource } = this;
    const lang = this.$language.current;
    let favicon: string | undefined;

    if (brand && brand.favicon) {
      favicon = brand.favicon;
    }

    if (hotel.favicon) {
      favicon = hotel.favicon;
    }

    const { og } = hotel;
    const globalFullNameAppends = hotel.globalFullName
      ? ` | ${hotel.globalFullName}`
      : '';
    const title = this.$t('value.hotelMetaTitle', {
      fullName: hotel.fullName,
      globalFullNameAppends,
    }) as string;
    const description = hotel.description;
    const ogImage = og.image;

    head.titleTemplate = (titleChunk) => {
      if (!titleChunk) {
        return title;
      }
      return `${titleChunk} | ${title}`;
    };

    head.meta = [
      {
        hid: 'description',
        name: 'description',
        content: description,
      },
      { hid: 'og:site_name', property: 'og:site_name', content: title },
      { hid: 'og:title', property: 'og:title', content: title },
      {
        hid: 'og:url',
        property: 'og:url',
        content: `${this.$navigation.origin}/${lang}/hotels/${hotel.slug}/`,
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: description,
      },
    ];

    if (ogImage) {
      head.meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: this.$res.img(ogImage),
      });
    }

    head.link = generateHreflang(this, { hotelSource });

    if (favicon) {
      head.link.push({
        hid: 'favicon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$res.img(favicon),
      });
    }

    const { style } = hotel;

    if (this.hotelHeaderIsActive) {
      head.htmlAttrs = {
        'data-hotel-header-active': '1',
      };
    }

    if (style) {
      head.style = [
        {
          cssText: style,
          type: 'text/css',
        },
      ];
    }

    return head;
  },
  mounted() {
    (window as any).setDebugPause = this.setDebugPause;

    // 宿GETS施設マスタはブラウザ側でマウントした後にロードする
    // 施設間を横断でSPA遷移した場合、このmountedフックはトリガーされないので、asyncData内で同様の処理をする
    this.$hotel.loadCurrentHotelYgetsDetail();
  },
  beforeDestroy() {
    if (process.browser) {
      delete (window as any).setDebugPause;
    }
  },
})
export default class HotelViewRoot extends mixins<NavigationHooksMixin>(
  NavigationHooksMixin,
) {
  $refs!: {
    galleriesModal: HHotelGalleriesModalRef;
  };

  readonly layout!: HDefaultLayout;

  hotelSource: HotelDetail = null as any;
  galleryModalActive: boolean = false;
  localNaviIsReached: boolean = false;

  /**
   * TOPページでスクロールアクティブなセクションID
   *
   * 'first-view'| 'feature'| 'guestroom' | 'default'
   */
  pageTopScrollActiveSectionId: sectionId = 'default';

  private debugPause: boolean = false;

  get brand() {
    return this.hotel.brand;
  }

  get logoIsScrollOuted() {
    if (!this.layout.isMounted) return false;
    return this.$window.scrollTop > 250;
  }

  get needHiddenHotelHeader() {
    return !this.logoIsScrollOuted;
  }

  get domId() {
    return `h-hotel--${this.hotel.slug}`;
  }

  get currentRouteName() {
    return this.$route.name;
  }

  get useLocalFooter() {
    return (
      this.currentRouteName ===
      'lang-hotels-hotel_slug-index-activities-activity_id'
    );
  }

  get hiddenFooter() {
    return (
      (this.isHotelTop && this.needHiddenHotelHeader) ||
      this.currentRouteName === 'lang-hotels-hotel_slug-index-roomsearch'
    );
  }

  get isHotelTop() {
    return this.currentRouteName === 'lang-hotels-hotel_slug-index';
  }

  get hotelHeaderIsActive() {
    return !this.isHotelTop || !this.needHiddenHotelHeader;
  }

  get needPauseAnimations() {
    return this.debugPause || this.galleryModalActive;
  }

  get hotel(): HotelDetail {
    return this.hotelSource;
  }

  get hasPhotoGroups() {
    return this.hotel.photoGroups.length > 0;
  }

  get hasActivities() {
    return !!this.hotel.activitySettings;
  }

  get hasRestaurantSettings() {
    return !!this.hotel.restaurantSettings;
  }

  get todo() {
    return this.hotel.todo;
  }

  filterSectionKey(key: string): boolean {
    const { hasActivities, hasRestaurantSettings, hasPhotoGroups, todo } = this;
    if (!hasActivities && key === 'activities') return false;
    if (!hasRestaurantSettings && key === 'dining') return false;
    if (!hasPhotoGroups && key === 'photogallery') return false;
    if (!todo && key === 'todo') return false;
    return true;
  }

  /**
   * 正規化済みの下層導線設定
   */
  get normalizedFlowLineSettings(): NormalizedHotelFlowLineSettings {
    return normalizeHotelFlowLineSettings(this.hotel.flowLineSettings, this);
  }

  /**
   * 下層導線設定を持っているか
   *
   * * 正規化済みのバナーが1件以上あるか
   */
  get hasFlowLineSettings() {
    return this.normalizedFlowLineSettings.banners.length > 0;
  }

  get navigationStack(): NavigationHooksStack {
    const directJumps = ['todo', 'activities', 'dining'];
    if (this.hotel.hasAccessContents) {
      directJumps.push('access');
    }
    const hotelName = this.hotel.name;

    return {
      /**
       * @MEMO
       *  当初、`"lang-hotels-hotel_slug-index"` のみを設定していたが、
       *  施設を横断するSPA遷移が発生した際に、ハンバーガーメニュー内のナビゲーションが更新されないという不具合が発生した。
       *  ここのIDは施設毎にユニークにしておかないと、コンポーネント側で変更検知できないので、URLスラッグを含めることにした。
       *
       *  ※しかし、本当はここの `local` というフィールドをgetter形式にしてやった方が良いとも思う。ここに依存している実装側でwatcher貼ってるのは保守観点上あまりよろしくない
       *
       * @see {@link https://hr-dev.backlog.jp/view/ACCO_CMS-1201}
       */
      id: `lang-hotels-hotel_slug-index:${this.hotel.slug}`,
      local: this.$theme.current.sections
        .filter(({ key }) => {
          if (key === 'feature' && !this.$hotel.hasFeature()) {
            return false;
          }

          if (key === 'todo' && !this.$hotel.hasTodo()) {
            return false;
          }

          if (key === 'activities' && !this.$hotel.hasActivity()) {
            return false;
          }

          if (
            key === 'dining' &&
            !this.$hotel.hasDining() &&
            !this.$theme.is('hoshinoya')
          ) {
            return false;
          }

          if (key === 'guestroom' && !this.$hotel.hasRoom()) {
            return false;
          }

          if (key === 'location' && !this.$hotel.hasLocation()) {
            return false;
          }

          return true;
        })
        .map(({ key, label, hiddenNavigation }) => {
          const toData = directJumps.includes(key)
            ? {
                path: key,
              }
            : {
                hash: key,
              };

          return {
            key,
            label: (label || '').replace('{{hotelName}}', hotelName),
            hidden: hiddenNavigation,
            to: this.$hotel.location(toData, this.hotel.slug),
          };
        })
        .filter((row) => {
          return this.filterSectionKey(row.key);
        }),
    };
  }

  topSectionId() {
    if (this.navigationStack.local) {
      return this.navigationStack.local[0].key;
    }
    return '';
  }

  showGallery() {
    this.galleryModalActive = true;
  }

  onRequestGallery() {
    this.showGallery();
  }

  /**
   * @memo
   *   現在利用していない。けど、利用価値はあるので残しておく
   *   特定のカテゴリのカルーセルを表示できる
   */
  onRequestGalleryCategory(category: string) {
    this.$refs.galleriesModal.showCarousel(null, category, false);
  }

  onRequestGalleryId(id: string) {
    this.$refs.galleriesModal.showCarousel(id, null);
  }

  onActivateNavigationHooks() {
    this.$hotel.setCurrent(this.hotelSource);
  }

  onDectivateNavigationHooks() {
    this.$hotel.setCurrent(null);
  }

  private setDebugPause(pause: boolean) {
    this.debugPause = pause;
  }
}
